.blink-slow {
    animation-name: blink-slow;
    animation-duration: 1200ms;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}
@keyframes blink-slow {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.blink-fast {
    animation-name: blink-fast;
    animation-duration: 700ms;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}
@keyframes blink-fast {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
